import {pozitiveNumberValidator} from "./pozitive-number-validator";

export const numberOfPages = (totalNumberOfItems: number, pageSize: number) => {
  if (
    !pozitiveNumberValidator(totalNumberOfItems) ||
    !pozitiveNumberValidator(pageSize)
  ) {
    return null;
  }

  const numberOfTotalPages = Math.ceil(totalNumberOfItems / pageSize);

  return numberOfTotalPages;
};
