import styled, {css} from "styled-components";
import {Block, BlockProps} from "./Block";

export interface FlexProps extends BlockProps {
  flex?: number;
  flexStart?: boolean;
  flexEnd?: boolean;
  center?: boolean;
  space?: boolean;
  grow?: number;
}

export const Flex = styled(Block)<FlexProps>`
  display: flex;

  ${({flex, flexStart, flexEnd, center, space, grow}) => {
    const justifyContet = flexStart
      ? "flex-start"
      : flexEnd
      ? "flex-end"
      : center
      ? "center"
      : space
      ? "space-between"
      : "";

    return css`
      justify-content: ${justifyContet};
      flex: ${flex ?? ""};
      flex-grow: ${grow ?? ""};
    `;
  }}

  > * {
    ${({gap}) =>
      gap &&
      css`
        padding-left: ${gap}px;
        padding-right: ${gap}px;
      `}
  }
`;
