import {pozitiveNumberValidator} from "./pozitive-number-validator";

export const pageRowNumber = (
  pageNumber: number,
  pageSize: number,
  index: number,
  indexIsZeroBased: boolean = true
) => {
  if (
    !pozitiveNumberValidator(pageNumber) ||
    !pozitiveNumberValidator(pageSize) ||
    !pozitiveNumberValidator(index, indexIsZeroBased)
  ) {
    return null;
  }

  const calculatedValue =
    (pageNumber - 1) * pageSize + (indexIsZeroBased ? index + 1 : index);

  return calculatedValue;
};
