import React, {useCallback, useEffect, useState} from "react";
import {CheckboxStatus} from "../enums/checkbox-status";

const isStatusDefined = (status: CheckboxStatus) =>
  Object.values(CheckboxStatus).includes(status);

export interface CheckboxProps {
  /**
   * Defines initial local state (currentStatus) but does not override status prop nor are changes registered by the component.
   */
  initialStatus?: CheckboxStatus;
  /**
   * Updates the local state (currentStatus) in real time.
   * While not defined, the component manages the local state on it's own
   */
  status?: CheckboxStatus;
  /**
   * Fires when the checkbox is clicked.
   * Props are self explanatory.
   */
  onClick?: (nextStatus: CheckboxStatus, prevStatus: CheckboxStatus) => any;
  /**
   * Defines if EMPTY is the next state when the previous state is INDETERMINATE
   */
  emptyAfterIndeterminate?: boolean;

  [key: string]: any;
}

export const Checkbox = ({
  initialStatus,
  status,
  onClick,
  emptyAfterIndeterminate,
  ...props
}: CheckboxProps) => {
  /**
   * Local state of component and single source of truth
   */
  const [currentStatus, setCurrentStatus] = useState<CheckboxStatus>(
    status ?? initialStatus ?? CheckboxStatus.EMPTY
  );

  /**
   * If status prop is defined, it should update the local state (currentStatus) in real time
   */
  useEffect(() => {
    if (isStatusDefined(status)) {
      setCurrentStatus(status);
    }
  }, [status]);

  /**
   * Single point of control over input element value!
   */
  const refCheckbox = useCallback(
    (el) => {
      if (el) {
        /**
         * Without this setTimeout, the refCheckbox does not update the input element value due to e.preventDefault(); in some cases.
         */
        setTimeout(() => {
          el.indeterminate = currentStatus === CheckboxStatus.INDETERMINATE;
          el.checked = currentStatus === CheckboxStatus.CHECKED;
        });
      }
    },
    [currentStatus]
  );

  return (
    <input
      type="checkbox"
      onClick={(e) => {
        /**
         * We want total control over the input element!
         * The input element value is updated via refCheckbox depending solely on the local state (currentStatus)
         */
        e.preventDefault();

        const {checked} = e.target as HTMLInputElement;

        const prevStatus = currentStatus;
        const nextStatus =
          checked &&
          (prevStatus !== CheckboxStatus.INDETERMINATE ||
            !emptyAfterIndeterminate)
            ? CheckboxStatus.CHECKED
            : CheckboxStatus.EMPTY;

        /**
         * Update the local state (currentStatus) only if there is no status prop defined!
         * If the status prop is defined, it should be the only way to update the local status (currentStatus)
         */
        if (!isStatusDefined(status)) {
          setCurrentStatus(nextStatus);
        }

        if (!onClick) {
          return;
        }

        onClick(nextStatus, prevStatus);
      }}
      ref={refCheckbox}
      {...props}
    />
  );
};
