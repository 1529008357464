import styled, {css} from "styled-components";
import {isNill} from "../helpers/is-nill";

export interface BlockProps {
  hideIfEmpty?: boolean;
  wPc?: number;
  wPx?: number;
  maxWpx?: number;
  hPc?: number;
  hPx?: number;
  maxHpx?: number;
  [key: string]: any;
}

export const Block = styled.div<BlockProps>`
  ${({wPc, wPx, maxWpx, hPc, hPx, maxHpx}) => {
    return css`
      width: ${!isNill(wPc) ? `${wPc}%` : !isNill(wPx) ? `${wPx}px` : ""};
      max-width: ${!isNill(maxWpx) ? `${maxWpx}px` : ""};
      height: ${!isNill(hPc) ? `${hPc}%` : !isNill(hPx) ? `${hPx}px` : ""};
      max-height: ${!isNill(maxHpx) ? `${maxHpx}px` : ""};
    `;
  }}

  &:empty {
    ${({hideIfEmpty}) => `display: ${hideIfEmpty ? "none" : ""}`};
  }
`;
